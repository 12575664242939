angular.module('fingerink')
.factory('session',['$rootScope','$window',function($rootScope, $window) {
    return {
        hasToken : function() {
            var session = $window.localStorage.session;
            if (session == null) {
                return false;
            }
            return true;
        },
        clear : function() {
            $rootScope.menu = null;
            $window.localStorage.clear();
        },
        put : function(session) {
            session.time = new Date();
            $window.localStorage.session = JSON.stringify(session);
        },
        update : function(newSession) {
            var session = this.get();
            newSession.operatives = session.operatives;
            $window.localStorage.session = JSON.stringify(newSession);
        },
        get : function() {
            if ($window.localStorage.session == null) {
                return null;
            }
            return JSON.parse($window.localStorage.session);
        },

        getRemainingCredits: ()=>{
            var c = { Advanced : 0,Fast : 0,InPerson : 0,SelfSigning : 0,Email : 0,Credits : 0, Templates:0};

            if ($window.localStorage.session == null) {
                return c;
            }

            var plan = JSON.parse($window.localStorage.session).plan;
            if(!plan || !plan.orderProduct || !plan.orderPeriods || !plan.orderPeriods[0] || !plan.orderPeriods[0].orderPeriodStates || !plan.orderPeriods[0].orderPeriodStates[0]){
                return c;
            }


            
            if(plan.orderProduct.signatureDistributed){
                if(plan.orderProduct.userDistributed){
                    c.advancedSignatures = (plan.orderProduct.advancedSignatures);
                    c.usedAdvancedSignatures = plan.orderPeriods[0].orderPeriodStates[0].usedAdvancedSignatures;
                    c.fastSignatures = (plan.orderProduct.fastSignatures);
                    c.usedfastSignatures = plan.orderPeriods[0].orderPeriodStates[0].usedFastSignatures;
                    c.inPersonSignatures = (plan.orderProduct.inPersonSignatures);
                    c.usedinPersonSignatures = plan.orderPeriods[0].orderPeriodStates[0].usedInPersonSignatures;
                    c.selfSignatures = (plan.orderProduct.selfSignatures);
                    c.usedselfSignatures = plan.orderPeriods[0].orderPeriodStates[0].usedSelfSignatures;
                    c.emailSignatures = (plan.orderProduct.emailSignatures);
                    c.usedemailSignatures = plan.orderPeriods[0].orderPeriodStates[0].usedEmailSignatures;
                    c.credits = (plan.orderProduct.credits);
                    c.usedcredits = plan.orderPeriods[0].orderPeriodStates[0].usedCredits;
                }else{
                    c.advancedSignatures = (plan.orderProduct.advancedSignatures * plan.licenses);
                    c.usedAdvancedSignatures = plan.orderPeriods[0].usedAdvancedSignatures;
                    c.fastSignatures = (plan.orderProduct.fastSignatures * plan.licenses);
                    c.usedfastSignatures = plan.orderPeriods[0].usedFastSignatures;
                    c.inPersonSignatures = (plan.orderProduct.inPersonSignatures * plan.licenses);
                    c.usedinPersonSignatures = plan.orderPeriods[0].usedInPersonSignatures;
                    c.selfSignatures = (plan.orderProduct.selfSignatures * plan.licenses);
                    c.usedselfSignatures = plan.orderPeriods[0].usedSelfSignatures;
                    c.emailSignatures = (plan.orderProduct.emailSignatures * plan.licenses);
                    c.usedemailSignatures = plan.orderPeriods[0].usedEmailSignatures;
                    c.credits = (plan.orderProduct.credits * plan.licenses);
                    c.usedcredits = plan.orderPeriods[0].usedCredits;
                }
            }else{
                if(plan.orderProduct.userDistributed){
                    c.advancedSignatures =  (plan.orderProduct.credits);
                    c.usedAdvancedSignatures = plan.orderPeriods[0].orderPeriodStates[0].usedCredits;
                    c.fastSignatures =  (plan.orderProduct.credits);
                    c.usedfastSignatures = plan.orderPeriods[0].orderPeriodStates[0].usedCredits;
                    c.inPersonSignatures =  (plan.orderProduct.credits);
                    c.usedinPersonSignatures = plan.orderPeriods[0].orderPeriodStates[0].usedCredits;
                    c.selfSignatures =  (plan.orderProduct.credits);
                    c.usedselfSignatures = plan.orderPeriods[0].orderPeriodStates[0].usedCredits;
                    c.emailSignatures =  (plan.orderProduct.credits);
                    c.usedemailSignatures = plan.orderPeriods[0].orderPeriodStates[0].usedCredits;
                    c.credits = (plan.orderProduct.credits);
                    c.usedcredits = plan.orderPeriods[0].orderPeriodStates[0].usedCredits;
                }else{
                    c.advancedSignatures = (plan.orderProduct.credits * plan.licenses);
                    c.usedAdvancedSignatures = plan.orderPeriods[0].usedCredits;
                    c.fastSignatures = (plan.orderProduct.credits * plan.licenses);
                    c.usedfastSignatures = plan.orderPeriods[0].usedCredits;
                    c.inPersonSignatures = (plan.orderProduct.credits * plan.licenses);
                    c.usedinPersonSignatures = plan.orderPeriods[0].usedCredits;
                    c.selfSignatures = (plan.orderProduct.credits * plan.licenses);
                    c.usedselfSignatures = plan.orderPeriods[0].usedCredits;
                    c.emailSignatures = (plan.orderProduct.credits * plan.licenses);
                    c.usedemailSignatures = plan.orderPeriods[0].usedCredits;
                    c.credits = (plan.orderProduct.credits * plan.licenses);
                    c.usedcredits = plan.orderPeriods[0].usedCredits; 
                }
            }

            if(plan.orderProduct.templateDistributed){
                c.templates = plan.orderProduct.templates;
                c.usedTemplates = plan.orderPeriods[0].orderPeriodStates[0].usedTemplates;
            }else{
                c.templates = (plan.orderProduct.templates* plan.licenses);
                c.usedTemplates = plan.orderPeriods[0].usedTemplates;
            }

            return c;
        }
    };
}]);